import React from 'react';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PdfStatementProps {
  statementId: string | null;
}

const PdfStatement: React.FC<PdfStatementProps> = ({ statementId }) => {
  const handlePdfClick = () => {
    if (statementId) {
      const pdfURL = `/path/to/pdf/${statementId}`; //TODO: Adjust this path accordingly
      window.open(pdfURL, '_blank');
    }
  };

  return (
    <FontAwesomeIcon
      icon={faFilePdf}
      onClick={handlePdfClick}
      className={!statementId ? 'disabled-icon' : ''}
    />
  );
};

export default PdfStatement;
