import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faHistory } from '@fortawesome/free-solid-svg-icons';
import { BillerLink } from '../lib/models';
import PdfStatement from './PdfStatement';
import './BillerCardFooter.css';


interface BillerCardFooterProps {
  biller: BillerLink;
  handleHistoryClick: () => void;
  handleDelete: () => void;
}

const BillerCardFooter: React.FC<BillerCardFooterProps> = ({ biller, handleHistoryClick, handleDelete }) => (
  <div className="biller-footer">
    <FontAwesomeIcon 
      icon={faHistory} 
      onClick={handleHistoryClick}
      className={(!biller.history || biller.history.length === 0) ? 'disabled-icon' : ''}
    />
    <PdfStatement statementId={biller.snapshot?.statementId || null} />
    <FontAwesomeIcon icon={faTrashCan} onClick={handleDelete} />
  </div>
);

export default BillerCardFooter;
