import React, { useState } from 'react';
import { applyMfa } from '../lib/functions';
import './BillerCardOtp.css';

interface BillerCardOtpProps {
  linkId: string;
  onOtpCodeSubmitted: () => void;
}

const OTPComponent: React.FC<BillerCardOtpProps> = ({ linkId, onOtpCodeSubmitted }) => {
  const [otp, setOtp] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  const handleOtpSubmit = async () => {
    setIsSubmitting(true);
    await applyMfa(linkId, otp);
    onOtpCodeSubmitted();
    setIsSubmitting(false);
  };

  return (
    <div className="biller-card-otp">
      <div className="otp-container">
        <input
          type="text"
          className="otp-input"
          placeholder="Enter One Time Password"
          value={otp} 
          onChange={handleOtpChange}
        />
        <button className="otp-submit-button" onClick={handleOtpSubmit} disabled={isSubmitting}>Verify</button>
      </div>
    </div>
  );
};

export default OTPComponent;
