import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { BillerLink } from '../../lib/models'; 
import { fetchLinkedBillers } from '../../lib/functions';

interface BillerState {
  billers: BillerLink[];
}

const initialState: BillerState = {
  billers: [],
};

export const billerSlice = createSlice({
  name: 'biller',
  initialState,
  reducers: {
    setBillers: (state, action: PayloadAction<BillerLink[]>) => {
      state.billers = action.payload;
    },
  },
});

export const { setBillers } = billerSlice.actions;

export const refreshBillers = (): AppThunk => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetchLinkedBillers()
      .then(billers => {
        dispatch(setBillers(billers));
        resolve(billers);
      })
      .catch((error) => {
        console.error('Error refreshing billers:', error);
        reject(error);
      });
  });
};

export const selectBillers = (state: RootState) => state.biller.billers;

export default billerSlice.reducer;
