import React, { useState } from 'react';
import { unlinkBiller } from '../lib/functions';
import { BillerLink } from '../lib/models';
import BillerCardDetails from './BillerCardDetails';
import BillerCardOtp from './BillerCardOtp';
import BillerCardFooter from './BillerCardFooter';
import BillerHistoryModal from './BillerHistoryModal';
import './BillerCard.css';

interface BillerCardProps {
  biller: BillerLink;
  onDeleted: () => void;
}

const BillerCard: React.FC<BillerCardProps> = ({ biller, onDeleted }) => {
  const [isHistoryExpanded, setHistoryExpanded] = useState(false);
  const [isOtpValidating, setIsOtpValidating] = useState(false);

  const dueDate = biller.snapshot?.balance?.dueDate ? new Date(biller.snapshot.balance.dueDate) : null;
  const dueAmount = biller.snapshot?.balance?.amountDue ? parseFloat(biller.snapshot.balance.amountDue) : null;

  const getColorClass = () => {
    switch (biller.status) {
      case 'success':
        return 'green';

      case 'otp_required':
      case 'otp_processing':
      case 'under_review':
      case 'scheduled':
        return 'orange';

      case 'expired':
      case 'error':
        return 'red';

      default:
        return 'grey';
    }
  }

  const handleHistoryClick = () => {
    setHistoryExpanded(true);
  };

  const closeHistoryModal = () => {
    setHistoryExpanded(false);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this biller?")) {
      await unlinkBiller(biller.id);
      onDeleted();
    }
  }

  const handleOtpCodeSubmitted = async () => {
    setIsOtpValidating(true);
  }

  const renderBillerDetails = () => {
    const { status, billerId } = biller;

    if (isOtpValidating || status === 'otp_processing') {
      return <p className="biller-detail">Validating...</p>;
    }

    if (status === 'pending') {
      return <p className="biller-detail">Linking... This may take a few minutes.</p>;
    }

    if (status === 'under_review' || status === 'scheduled') {
      return <p className="biller-detail">We are reviewing this connection.</p>;
    }

    if (status === 'error') {
      return <p className="biller-detail error">Oops! Looks like we hit a snag. Don't worry, we're on it!</p>;
    }

    if (status === 'otp_required') {
      return <BillerCardOtp linkId={biller.id} onOtpCodeSubmitted={handleOtpCodeSubmitted} />;
    }

    return <BillerCardDetails billerId={billerId} dueAmount={dueAmount} dueDate={dueDate} />;
  }

  return (
    <div className="biller">
      <div className="biller-content">
        <div className={`status ${getColorClass()}`}></div>
        <h3>{biller.name}</h3>
        {renderBillerDetails()}
      </div>
      <BillerCardFooter biller={biller} handleHistoryClick={handleHistoryClick} handleDelete={handleDelete} />
      <BillerHistoryModal biller={biller} isHistoryExpanded={isHistoryExpanded} closeHistoryModal={closeHistoryModal} />
    </div>
  );
}

export default BillerCard;
