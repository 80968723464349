import React from 'react';
import './LoadingOverlay.css';

const LoadingOverlay: React.FC = () => {
  return (
    <div className="loading-overlay">
      <i className="fas fa-spinner loading-icon"></i> 
    </div>
  );
};

export default LoadingOverlay;
