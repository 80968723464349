import React from 'react';
import { BillerLink, Snapshot } from '../lib/models';
import CloseButton from './CloseButton';
import PdfStatement from './PdfStatement';
import './BillerHistoryModal.css';

interface BillerHistoryModalProps {
  biller: BillerLink;
  isHistoryExpanded: boolean;
  closeHistoryModal: () => void;
}

const BillerHistoryModal: React.FC<BillerHistoryModalProps> = ({ biller, isHistoryExpanded, closeHistoryModal }) => {
  if (!isHistoryExpanded || !biller.history) {
    return null;
  }
  return (
    <div className="history-modal" onClick={closeHistoryModal}>
      <div className="history-modal-content" onClick={e => e.stopPropagation()}>
        <CloseButton onClick={closeHistoryModal} />
        <h4>Timeline:</h4>
        {Array.from(biller.history).map((snapshot) => (
          <div key={new Date(snapshot.timestamp).toLocaleDateString()} className="history-item">
            <span className="history-item-date">{new Date(snapshot.balance.dueDate).toLocaleDateString()}</span>
            <span className="history-item-amount">{parseFloat(snapshot.balance.amountDue).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
            <PdfStatement statementId={biller.snapshot?.statementId || null} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BillerHistoryModal;
