import { setBillers, store } from '../redux';
import { getToken, onMessage, MessagePayload } from "firebase/messaging";
import { messaging } from "./firebase";
import { updateFcmToken } from "./functions";
import { getDeviceId } from "./device";
import { fetchLinkedBillers } from '../lib/functions';


export async function setupMessaging() {
  const vapidKey = process.env.FIREBASE_MESSAGING_VAPID_KEY;
  let currentToken: string;

  try {
    await requestPermission();
    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
    
    const updateToken = async () => {
      const token = await getToken(messaging, {
        serviceWorkerRegistration: registration,
        vapidKey: vapidKey
      });
      console.log("FCM token:", token);
      await updateFcmToken(getDeviceId(), token);
    }
    
    window.addEventListener('load', updateToken);
    window.addEventListener('login', updateToken);
    
    onMessage(messaging, handleMessage);

    const checkForTokenUpdate = async () => {
      const newToken = await getToken(messaging);
      if (newToken !== currentToken) {
        console.log('Token updated');
        currentToken = newToken;
        updateToken(); 
      }
    };
    
    // Run the checkForTokenUpdate function every 5 minutes after the first check in 10 seconds
    setTimeout(checkForTokenUpdate, 10 * 1000);
    setInterval(checkForTokenUpdate, 5 * 60 * 1000);

  } catch (error) {
    console.error("Error during messaging setup:", error);
    throw error;
  }
}

function requestPermission() {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    } else {
      console.log('Notification permission NOT granted: ' + permission);
    }
  });
}

async function handleMessage(payload: MessagePayload) {
  console.log("Message received:", payload);

  switch(payload?.data?.event) {
    default: 
    const data = await fetchLinkedBillers();
    store.dispatch(setBillers(data));
    break;
  }
}
