import React, { useState } from 'react';
import { BillerLink } from '../lib/models';
import { BillerContext } from '../context/BillerContext';

interface Props {
  children: React.ReactNode;
}

export const BillerProvider: React.FC<Props> = ({ children }) => {
  const [billers, setBillers] = useState<BillerLink[]>([]);

  return (
    <BillerContext.Provider value={{ billers, setBillers }}>
      {children}
    </BillerContext.Provider>
  );
};
