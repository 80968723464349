import React from 'react';
import Menu from './Menu';
import './Header.css';

interface HeaderProps {
    userDisplayName: string;
    onLogout: () => void;
    onAddBiller: () => void;
    onSuggestBiller: () => void;
  }

  const Header: React.FC<HeaderProps> = ({ userDisplayName, onLogout, onAddBiller, onSuggestBiller }) => {
    return (
      <header className="app-header">
        <div className="logo-container">
          <img src="/logo.svg" alt="BeyondPay Logo" className="logo" />
          <h1>BeyondPay</h1>
        </div>
        <div className="user-info">
          <h2>{userDisplayName}</h2>
          <Menu
            onAddBiller={onAddBiller}
            onSuggestBiller={onSuggestBiller}
            onLogout={onLogout}
          />
        </div>
      </header>
    );
  };
  
  export default Header;
