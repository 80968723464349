import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';
import { BillerLink, BillerDetails, Snapshot, SuggestedBiller } from './models';

export function fetchLinkedBillers(): Promise<BillerLink[]> {
  const call = httpsCallable(functions, 'getBillerLinks');
  return call({})
    .then(result => result.data as BillerLink[])
    .catch(error => {
      console.error('Error:', error);
      return [];
    }
  );
}

export function fetchAvailableBillers(): Promise<BillerLink[]> {
  const call = httpsCallable(functions, 'getAllBillers');
  return call({})
    .then(result => result.data as BillerLink[])
    .catch(error => {
      console.error('Error:', error);
      return [];
    }
  );
}

export function getBillerDetails(billerId: string): Promise<BillerDetails> {
  const call = httpsCallable(functions, 'getBillerDetails');
  return call({billerId: billerId})
    .then(result => result.data as BillerDetails)
    .catch(error => {
      console.error('Error:', error);
      throw error;
    }
  );
}

export function linkBiller(billerId: string, name: string, loginFields: Map<string,string>): Promise<BillerLink> {
  const call = httpsCallable(functions, 'linkBiller');
  return call({billerId, name, loginFields: Object.fromEntries(loginFields)})
    .then(result => {
      if(result.data) {
        return result.data as BillerLink;
      } else {
        throw new Error("No data received from function");
      }
    })
    .catch(error => {
      console.error('Error:', error);
      throw error;
    }
  );
}

export function applyMfa(linkId: string, otp: string): Promise<void> {
  const call = httpsCallable(functions, 'applyMfa');
  return call({linkId, otp})
    .then(result => {
      console.log('MFA has been applied for link id: ', result);
    })
    .catch(error => {
      console.error('Error:', error);
      throw error;
    }
  );
}

export function unlinkBiller(linkId: string): Promise<void> {
  const call = httpsCallable(functions, 'unlinkBiller');
  return call({linkId})
    .then((result) => {
      console.log('Biller has been successfully unlinked: ', result);
    })
    .catch(error => {
      console.error('Error:', error);
      throw error;
    });
}

export function suggestBiller(data: SuggestedBiller): Promise<void> {
  const call = httpsCallable(functions, 'suggestBiller');
  return call({data})
    .then((result) => {
      console.log('Biller suggestion has been successfully submitted: ', result);
    })
    .catch(error => {
      console.error('Error:', error);
      throw error;
    }
  );
}

export function updateFcmToken(deviceId: string, token: String): Promise<void> {
  console.log("FCM Token: " + token);
  const call = httpsCallable(functions, 'updateFcmToken');
  return call({deviceId, token})
    .then((result) => {
      console.log('FCM token updated: ', result);
    })
    .catch(error => {
      console.error('Error:', error);
      throw error;
    }
  );
}
