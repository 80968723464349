import { auth, functions } from './firebase';
import { httpsCallable, HttpsCallable } from "firebase/functions";
import { GoogleAuthProvider, signInWithPopup, signOut, UserCredential } from "firebase/auth";

const CREATE_USER_FUNCTION_NAME = 'createUser';

interface CreateUserData {
  strukdId: string;
}

export async function logInWithGoogle(): Promise<UserCredential> {
  const provider = new GoogleAuthProvider();
  const userCredential = await signInWithPopup(auth, provider);

  const createUser: HttpsCallable = httpsCallable(functions, CREATE_USER_FUNCTION_NAME);
  const result = await createUser({ uid: userCredential.user?.uid });
  const data = result.data as CreateUserData;
  const strukdId = data.strukdId;

  // Dispatch 'login' event
  const loginEvent = new Event('login');
  window.dispatchEvent(loginEvent);

  return userCredential;
}

export function logOff(): Promise<void> {
  return signOut(auth);
}
