import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from './redux';
import { setupMessaging } from './lib/messaging';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import NotificationPermission from './components/NotificationPermission';
import LoadingOverlay from './components/LoadingOverlay';
import { BillerProvider } from './components/BillerProvider';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import { AuthProvider, useAuth } from './context/AuthContext';
import { HelmetProvider } from 'react-helmet-async'; 
import './App.css';


const App = () => {
  const { user, loading } = useAuth();

  useEffect(() => {
    if (user) {
      setupMessaging();
    }
  }, [user]);

  if (loading) {
    return (
      <LoadingOverlay />
    );
  }

  if (!user) {
    return <Login />;
  } else {
    return (
      <div>
        <NotificationPermission>
          <BillerProvider>
            <Dashboard />
          </BillerProvider>
          <ToastContainer />
        </NotificationPermission>
        <Footer />
      </div>
    );
  }
}

const AppWithAuth = () => (
  <Provider store={store}>
    <AuthProvider>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </AuthProvider>
  </Provider>
);

export default AppWithAuth;
