import React, { useState, useEffect, ChangeEvent } from 'react';
import { fetchAvailableBillers, getBillerDetails, linkBiller } from '../lib/functions';
import { BillerLink, BillerDetails, Field } from '../lib/models';
import LoadingOverlay from './LoadingOverlay';
import CloseButton from './CloseButton';
import './AddBiller.css';


interface AddBillerProps {
    onBillerAdded: () => Promise<void>
    onAddBillerClosed: () => void
}

const AddBiller: React.FC<AddBillerProps> = ({ onBillerAdded, onAddBillerClosed }) => {
    const [billers, setBillers] = useState<BillerLink[]>([]);
    const [selection, setSelectedBiller] = useState<BillerDetails | null>(null);
    const [fields, setFields] = useState<Map<string, string>>(new Map());
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchBillers = async () => {
            setIsLoading(true);
            try {
                const availableBillers = await fetchAvailableBillers();
                setBillers(availableBillers);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchBillers();
    }, []); 

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFields(new Map(fields.set(event.target.name, event.target.value)));
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (selection) {
            setIsLoading(true);
            try {
                await linkBiller(selection.biller.id, selection.biller.name, fields);
                onBillerAdded();
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const handleClose = async () => {
        onAddBillerClosed();
    }

    const handleSelectBiller = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const billerId = event.target.value;
        setIsLoading(true);
        try {
            const billerDetails = await getBillerDetails(billerId);
            setSelectedBiller(billerDetails || null);
        } catch (error) {
            console.error('Failed to get biller details:', error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="add-biller-dialog">
            { isLoading && <LoadingOverlay /> }
            <div className='add-biller-content'>
                <CloseButton onClick={handleClose} />
                <form className="add-biller-form" onSubmit={handleSubmit}>
                    <select value={selection?.biller.id || ''} onChange={handleSelectBiller}>
                        {isLoading ? (
                            <option>Loading Billers...</option>
                        ) : (
                            <>
                            <option value=''>Select a Biller...</option>
                            {billers.map(biller => (
                                <option key={biller.id} value={biller.id}>{biller.name}</option>
                            ))}
                            </>
                        )}
                    </select>
                    {selection && selection.loginFields.map((field: Field) => (
                        <div key={field.name}>
                            <label>{field.displayName}</label>
                            <input
                                type={field.secret ? 'password' : 'text'}
                                name={field.name}
                                onChange={handleInputChange}
                            />
                        </div>
                    ))}

                    <button type="submit">Link Biller</button>
                </form>
            </div>
        </div>
    );
}

export default AddBiller;
