import React, { useEffect } from 'react';
import './Footer.css';

const Footer = () => {
  useEffect(() => {
    const handleScroll = () => {
      const footerElement = document.querySelector('.site-footer');

      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 50) {
        footerElement?.classList.add('scrolled-to-bottom');
      } else {
        footerElement?.classList.remove('scrolled-to-bottom');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className="site-footer">
      <div className="powered-by">
        Powered by <a href="https://strukd.com/" className="strukd-link">Strukd API</a>
      </div>
      <div className="footer-content">
        <a href="/legal/privacy.html" target="_blank" rel="noopener noreferrer">Privacy</a> |
        <a href="/legal/terms.html" target="_blank" rel="noopener noreferrer">Terms</a> |
        <a href="/legal/security.html" target="_blank" rel="noopener noreferrer">Security</a> |
        <a href="/legal/copyright.html" target="_blank" rel="noopener noreferrer">Copyright</a> |
        <a href="/legal/cookies.html" target="_blank" rel="noopener noreferrer">Cookies</a>
      </div>
    </footer>
  );
};

export default Footer;
