import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { logInWithGoogle } from '../lib/auth';
import { useAuth } from '../context/AuthContext';
import './Login.css';

const Login: React.FC = () => {
  const { loading } = useAuth();

  const handleLoginWithGoogle = async () => {
    if (!loading) {
      try {
        await logInWithGoogle();
      } catch (error) {
        console.error("Login error: ", (error as any).message);
      }
    }
  }

  return (
    <div className="login-container">
      <Helmet>
        <meta name="description" content="Your Ultimate Bill Management Solution." />
        <title>Beyondpay</title>
      </Helmet>
      <div className="landing-container">
        <h1>Beyondpay</h1>
        <p>Your Ultimate Bill Management Solution. Simplify your financial management journey with us.</p>
        <button onClick={handleLoginWithGoogle}>Join Now / Sign In</button>
      </div>
    </div>
  );
}

export default Login;
