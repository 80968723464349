import React, { ReactNode, useState, useEffect } from 'react';
import './NotificationPermission.css';

interface Props {
  children: ReactNode;
}

const NotificationPermission: React.FC<Props> = ({ children }) => {
  const notificationsSupported = typeof Notification !== 'undefined';

  const [notificationsEnabled, setNotificationsEnabled] = useState(
    notificationsSupported && Notification.permission === 'granted'
  );
  const [isBlocked, setIsBlocked] = useState(
    notificationsSupported && Notification.permission === 'denied'
  );

  const requestPermission = () => {
    if (!notificationsSupported) {
      console.error('Notifications not supported in this browser');
      return;
    }

    Notification.requestPermission()
      .then((permission) => {
        if (permission === 'granted') {
          setNotificationsEnabled(true);
          setIsBlocked(false);
          console.info('Notification permission granted');
        } else if (permission === 'denied') {
          setIsBlocked(true);
          console.error('Notification permission denied');
        }
      })
      .catch((error) => {
        console.error('Failed to request notification permission:', error);
      });
  };

  useEffect(() => {
    if (!notificationsSupported) {
      console.warn('Notifications not supported in this browser');
      return;
    }

    if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
      requestPermission();
    }
  }, [notificationsSupported]); 

  if (!notificationsSupported || notificationsEnabled) {
    return <>{children}</>;
  }

  return (
    <div className="notification-container">
      <h1>Notifications</h1>
      <p>To use our service, please enable notifications.</p>
      {isBlocked ? (
        <p className="error">
          It seems you've blocked notifications.<br />Please enable them from your browser settings.
        </p>
      ) : (
        <button onClick={requestPermission}>Enable Notifications</button>
      )}
    </div>
  );
};

export default NotificationPermission;
