import React from 'react';
import { BillerLink } from '../lib/models';

interface BillerContextState {
  billers: BillerLink[];
  setBillers: (billers: BillerLink[]) => void;
}

export const BillerContext = React.createContext<BillerContextState>({
  billers: [],
  setBillers: (billers: BillerLink[]) => {}
});
