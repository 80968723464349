import React from 'react';
import './BillerCardDetails.css';
import BillerLogo from './BillerLogo';

interface BillerDetailsProps {
  billerId: string;
  dueAmount: number | null;
  dueDate: Date | null;
}

const BillerCardDetails: React.FC<BillerDetailsProps> = ({ billerId, dueAmount, dueDate }) => {
  return (
    <div className="biller-details-container">
      <BillerLogo billerId={billerId} />
      <table className="biller-details-table">
        <tbody>
          <tr>
            <td className="biller-detail-label">Amount Due:</td>
            <td className="biller-detail-value">{dueAmount !== null ? dueAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "N/A"}</td>
          </tr>
          <tr>
            <td className="biller-detail-label">Due Date:</td>
            <td className="biller-detail-value">{dueDate ? dueDate.toLocaleDateString() : "No date provided"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default BillerCardDetails;
