import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, refreshBillers, RootState } from '../redux';
import { Helmet } from 'react-helmet-async';
import { logOff } from '../lib/auth';
import { useAuth } from '../context/AuthContext';
import Header from './Header';
import BillerCard from './BillerCard';
import AddBiller from './AddBiller';
import SuggestBiller from './SuggestBiller';
import LoadingOverlay from './LoadingOverlay';
import '../styles/buttons.css';
import './Dashboard.css'
import { BillerLink } from '../lib/models';

const Dashboard = () => {
  const { user } = useAuth();
  const [addingBiller, setAddingBiller] = useState(false);
  const [suggestingBiller, setSuggestingBiller] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const billers = useSelector((state: RootState) => state.biller.billers);

  const withLoading = (asyncFunc: (...args: any[]) => Promise<any>) =>
  async (...args: any[]): Promise<void> => {
    setIsLoading(true);
    try {
      await asyncFunc(...args);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshBillersWithLoading = useCallback(
    withLoading(async () => {
      if (user) {
        await dispatch(refreshBillers());
      }
    }),
    [dispatch, user]
  );

  const handleLogout = withLoading(async () => {
    await logOff();
  });

  const handleCloseAddBiller = async () => {
    setAddingBiller(false);
  };
  
  const handleBillerAdded = withLoading(async () => {
    await refreshBillersWithLoading();
    handleCloseAddBiller();
  });

  const handleSuggestBillerClosed = async () => {
    setSuggestingBiller(false); 
  }

  const handleBillerSuggested = withLoading(async () => {
    await refreshBillersWithLoading();
    handleSuggestBillerClosed();
  });

  useEffect(() => {
    refreshBillersWithLoading();
  }, [user, refreshBillersWithLoading]);


  return (
    <div className="dashboard-container">
      <Helmet>
        <meta name="description" content="Dashboard of the application" />
        <title>Dashboard</title>
      </Helmet>
      <Header 
        userDisplayName={user?.displayName || ''}
        onLogout={handleLogout}
        onAddBiller={() => setAddingBiller(true)}
        onSuggestBiller={() => setSuggestingBiller(true)}
      />

      {!isLoading && billers.length === 0 ? (
          <div className="cta-message">
              You haven't connected any billers yet. Start by linking your first biller connection.
          </div>
      ) : (
          <div className="biller-cards-container">
              {billers.map((biller: BillerLink) => 
                  <BillerCard biller={biller} key={biller.id} onDeleted={refreshBillersWithLoading} />
              )}
          </div>
      )}

      {addingBiller && <AddBiller onBillerAdded={handleBillerAdded} onAddBillerClosed={handleCloseAddBiller} />}
      {suggestingBiller && <SuggestBiller onBillerSuggested={handleBillerSuggested} onSuggestBillerClosed={handleSuggestBillerClosed} />}
      {isLoading && <LoadingOverlay />}
    </div>
  );
}

export default Dashboard;
