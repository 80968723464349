import React, { useState } from 'react'
import { suggestBiller } from '../lib/functions';
import { SuggestedBiller } from '../lib/models';
import CloseButton from './CloseButton';
import './SuggestBiller.css';

interface SuggestBillerProps {
    onBillerSuggested: () => Promise<void>
    onSuggestBillerClosed: () => void
}

const SuggestBiller: React.FC<SuggestBillerProps> = ({ onBillerSuggested, onSuggestBillerClosed }) => {
    const [billerName, setBillerName] = useState('');
    const [billerWebsite, setBillerWebsite] = useState('');
    const [loginUrl, setLoginUrl] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isMfaRequired, setIsMfaRequired] = useState(false);
    const [comments, setComments] = useState('');

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const data: SuggestedBiller = {
            billerName,
            billerWebsite,
            loginUrl,
            username,
            password,
            isMfaRequired,
            comments,
        };

        try {
            await suggestBiller(data);
            onBillerSuggested();
        } catch (error) {
            console.error('Error:', error);
        }
    };
      
    const handleClose = () => {
        onSuggestBillerClosed();
    }

    return (
        <div className="suggest-biller-dialog">
            <div className='suggest-biller-content'>
                <CloseButton onClick={handleClose} />
                <form className="suggest-biller-form" onSubmit={handleSubmit}>
                    <input type="text" value={billerName} onChange={(e) => setBillerName(e.target.value)} placeholder="Biller Name" required />
                    <input type="url" value={billerWebsite} onChange={(e) => setBillerWebsite(e.target.value)} placeholder="Biller Website" required />
                    <input type="url" value={loginUrl} onChange={(e) => setLoginUrl(e.target.value)} placeholder="Login Url" required />
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                    <label>
                        <input type="checkbox" checked={isMfaRequired} onChange={(e) => setIsMfaRequired(e.target.checked)} />
                        Multi-Factor Authentication (MFA) required?
                    </label>
                    <textarea value={comments} onChange={(e) => setComments(e.target.value)} placeholder="Comments" />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
}

export default SuggestBiller;
