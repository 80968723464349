import React, { useState } from 'react';
import './BillerLogo.css';

interface BillerLogoProps {
  billerId: string;
}

const BillerLogo: React.FC<BillerLogoProps> = ({ billerId }) => {
  const logoUrl = `https://strukd.com/logos/${billerId}.png`;
  const defaultLogoUrl = "https://strukd.com/logos/default.png";
  
  const [imageSrc, setImageSrc] = useState(logoUrl);
  
  const handleError = () => {
    // Set the default logo URL only if it's not already set
    if (imageSrc !== defaultLogoUrl) {
      setImageSrc(defaultLogoUrl);
    }
  };

  return (
    <img
      className="biller-logo"
      src={imageSrc}
      onError={handleError}
      alt={`${billerId} logo`}
    />
  );
};

export default BillerLogo;
