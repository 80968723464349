import { useEffect, useRef, useState } from 'react';
import './Menu.css';

interface MenuProps {
    onLogout: () => void;
    onAddBiller: () => void;
    onSuggestBiller: () => void;
}

const Menu: React.FC<MenuProps> = ({ onLogout, onAddBiller, onSuggestBiller }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);

    // Close the menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    const handleMenuToggle = () => {
        setIsOpen(prevState => !prevState);
    }

    const handleMenuItemClick = (action: () => void) => {
        action();
        setIsOpen(false); 
    }

    return (
        <div>
            <div className="menu-container">
                <div className="hamburger-icon" onClick={handleMenuToggle}>
                    <span />
                    <span />
                    <span />
                </div>
                <div ref={menuRef} className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
                    <button className="menu-item" onClick={() => handleMenuItemClick(onAddBiller)}>Link Biller</button>
                    <button className="menu-item" onClick={() => handleMenuItemClick(onSuggestBiller)}>Suggest Biller</button>
                    <div className="menu-divider"></div>
                    <button className="menu-item" onClick={() => handleMenuItemClick(onLogout)}>Log out</button>
                </div>
            </div>
        </div>
    );
};

export default Menu;
