import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import ReduxThunk from 'redux-thunk';
import billerReducer from './slices/billerSlice';
import alertReducer from './slices/alertSlice';

const rootReducer = {
  biller: billerReducer,
  alert: alertReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(ReduxThunk), 
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
export * from './slices/billerSlice';
